import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Banner from '../Header/Banner';
import { HomeText } from '../Home/Home';
import {
  loggedIn,
  NextGatherInfoUrl,
  StateDropDown,
  useAdSource,
} from 'dsoa-components';
import { courseCanEnroll, courseEnroll, stateByAbbreviation } from 'dsoapi';
import SDDACA from './SDDACA';
import SDDACO from './SDDACO';
import SDDACT from './SDDACT';
import SDDADE from './SDDADE';
import SDDAFL from './SDDAFL';
import SDDAMN from './SDDAMN';
import SDDANJ from './SDDANJ';
import SDDAOR from './SDDAOR';
import SDDAPA from './SDDAPA';
import SDDATN from './SDDATN';
import SDDAWA from './SDDAWA';
import SDDA from './SDDA';

const CoursesForState = ({ setTitle = () => {} }) => {
  const [imageUrl, setImageUrl] = useState('');
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [courseAbbreviation, setCourseAbbreviation] = useState('SDDA');
  const [isValid, setIsValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const { stateAbbreviation } = useParams();
  const navigate = useNavigate();
  useAdSource();
  const selectedState = stateAbbreviation?.toUpperCase();

  useEffect(() => {
    const fetchData = async () => {
      const response = await stateByAbbreviation(selectedState);
      setImageUrl(response.data.imageUrl);
    };
    fetchData();
  }, [imageUrl, selectedState]);

  const onEnroll = async () => {
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    if (isEnrolling) {
      return;
    }
    setIsEnrolling(true);
    const canEnrollRes = await courseCanEnroll(courseAbbreviation);
    if (canEnrollRes.data.canEnroll) {
      const enrollRes = await courseEnroll(courseAbbreviation, {
        siteRegisteredWith: 'seniordrivingdiscount',
      });
      const url = await NextGatherInfoUrl(
        enrollRes.data.userCourseEnrollmentId
      );
      navigate(url);
    } else {
      setIsEnrolling(false);
      navigate('/dashboard');
    }
  };

  const onSignup = () => {
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    localStorage.setItem('signupCourse', courseAbbreviation);
    navigate('/signup');
  };

  const renderState = () => {
    switch (selectedState) {
      case 'CA':
        setTitle('California');
        return (
          <SDDACA
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'CO':
        setTitle('Colorado');
        return (
          <SDDACO
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'CT':
        setTitle('Connecticut');
        return (
          <SDDACT
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'DE':
        setTitle('Delaware');
        return (
          <SDDADE
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'FL':
        setTitle('Florida');
        return (
          <SDDAFL
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'MN':
        setTitle('Minnesota');
        return (
          <SDDAMN
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'NJ':
        setTitle('New Jersey');
        return (
          <SDDANJ
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'OR':
        setTitle('Oregon');
        return (
          <SDDAOR
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'PA':
        setTitle('Pennsylvania');
        return (
          <SDDAPA
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'TN':
        setTitle('Tennessee');
        return (
          <SDDATN
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      case 'WA':
        setTitle('Washington');
        return (
          <SDDAWA
            courseAbbreviation={courseAbbreviation}
            setCourseAbbreviation={setCourseAbbreviation}
            setIsValid={setIsValid}
          />
        );
      default:
        setTitle(selectedState);
        return (
          <SDDA
            setIsValid={setIsValid}
            stateAbbreviation={selectedState}
            setCourseAbbreviation={setCourseAbbreviation}
          />
        );
    }
  };

  return (
    <>
      <Banner
        primary="Senior Driving Discount of America"
        secondary="A Driving School of America Company"
      />
      <div className="ui main container">
        <HomeText />
        <StateDropDown
          placeholder="Select another state"
          onSelectState={(st) => navigate(`/state/${st}`)}
        />
        <br />
        {stateAbbreviation && renderState()}

        <div className="ui centered grid">
          <div className="row">
            <div className="column">
              {!isValid && showValidation && (
                <p className="error">Please select a course</p>
              )}
              {loggedIn() ? (
                <button
                  className="fluid ui primary button"
                  onClick={() => onEnroll()}
                  disabled={isEnrolling}
                >
                  {isEnrolling ? 'Enrolling...' : 'Enroll'}
                </button>
              ) : (
                <button
                  className="fluid ui primary button"
                  onClick={() => onSignup()}
                >
                  Sign Up
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoursesForState;
