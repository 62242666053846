import './Sdda.css';
import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { studentInfo as getStudentInfo } from 'dsoapi';
import Banner from './Header/Banner';
import CoursesForState from './CoursesForState/CoursesForState';
import Header from './Header/Header';
import Home from './Home/Home';
import Promo from './Promo';
import SignUp from './SignUp';
import {
  Contact,
  Course,
  Dashboard,
  EditProfile,
  EmailSettings,
  ForgotPassword,
  GatherCourseInfo,
  HelpPage,
  HelpPageCategories,
  Login,
  LoginAsStudent,
  NotFound,
  OAuthGoogle,
  Payment,
  UserContext,
  loggedIn,
  isAdmin,
} from 'dsoa-components';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.doSetStudentInfo = (info) => {
      this.setState({ studentName: info?.studentName });
      this.setState({ emailAddress: info?.emailAddress });
    };

    this.state = {
      studentName: '',
      emailAddress: '',
      setStudentInfo: this.doSetStudentInfo,
    };
  }

  checkToken = async () => {
    if (loggedIn()) {
      const response = await getStudentInfo();
      this.setState({ studentName: response.data.studentName });
      this.setState({ emailAddress: response.data.emailAddress });
    }
  };

  componentDidMount() {
    this.checkToken();
  }

  setTitle = (title) => {
    document.title = 'Senior Driving Discount of America - ' + title;
  };

  render() {
    return (
      <div>
        <UserContext.Provider value={this.state}>
          <BrowserRouter>
            <Header />
            <Routes>
              <Route path="/" element={<Home setTitle={this.setTitle} />} />
              <Route
                path="/courseapp/:hashLink"
                element={
                  <RequireAuth>
                    <Course setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/dashboard/*"
                element={
                  <RequireAuth>
                    <Banner
                      primary="Senior Driving Discount of America"
                      secondary="Student Dashboard"
                    />
                    <Dashboard setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/editprofile"
                element={
                  <RequireAuth>
                    <EditProfile setTitle={this.setTitle} />
                  </RequireAuth>
                }
              />
              <Route
                path="/forgotpassword/*"
                element={
                  <ForgotPassword site="SDDA" setTitle={this.setTitle} />
                }
              />
              <Route
                path="/gatherinfo/:infoPage/:uceId"
                element={
                  <RequireAuth>
                    <GatherCourseInfo
                      showRegistrationSteps={false}
                      setTitle={this.setTitle}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/help/:titleSlug"
                element={
                  <HelpPage
                    site="SDDA"
                    setTitle={this.setTitle}
                    banner={
                      <Banner
                        primary="Senior Driving Discount of America"
                        secondary="A Driving School of America Company"
                      />
                    }
                  />
                }
              />
              <Route
                path="/help"
                element={
                  <HelpPageCategories
                    site="SDDA"
                    title="Help Pages"
                    setTitle={this.setTitle}
                    banner={
                      <Banner
                        primary="Senior Driving Discount of America"
                        secondary="A Driving School of America Company"
                      />
                    }
                  />
                }
              />
              <Route
                path="/login"
                element={<Login setTitle={this.setTitle} />}
              />
              <Route
                path="/loginasstudent"
                element={
                  <RequireAdminAuth>
                    <LoginAsStudent setTitle={this.setTitle} />
                  </RequireAdminAuth>
                }
              />
              <Route
                path="/payment/:uceId"
                element={
                  <RequireAuth>
                    <Payment
                      siteName="Senior Driving Discount of America"
                      setTitle={this.setTitle}
                    />
                  </RequireAuth>
                }
              />
              <Route
                path="/oauth2/google/*"
                element={<OAuthGoogle site="SDDA" />}
              />
              <Route
                path="/promo"
                element={<Promo setTitle={this.setTitle} />}
              />
              <Route
                path="/signup"
                element={<SignUp setTitle={this.setTitle} />}
              />
              <Route
                path="/state/:stateAbbreviation/*"
                element={<CoursesForState setTitle={this.setTitle} />}
              />
              <Route
                path="/emailsettings/:emailLogId"
                element={<EmailSettings setTitle={this.setTitle} />}
              />
              <Route
                path="/contact"
                element={<Contact site="SDDA" setTitle={this.setTitle} />}
              />
              <Route
                path="*"
                element={<NotFound redirectUrl="/" setTitle={this.setTitle} />}
              />
            </Routes>
          </BrowserRouter>
        </UserContext.Provider>
      </div>
    );
  }
}

const RequireAuth = ({ children }) => {
  return loggedIn() ? children : <Navigate to="/" />;
};

const RequireAdminAuth = ({ children }) => {
  return isAdmin() ? children : <Navigate to="/" />;
};

export default App;
