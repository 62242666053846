import React from 'react';
import Banner from '../Header/Banner';
import HomeImages from './HomeImages';
import { useNavigate } from 'react-router-dom';
import { StateDropDown, loggedIn, useAdSource } from 'dsoa-components';

const Home = ({ setTitle = () => {} }) => {
  const navigate = useNavigate();
  useAdSource();

  setTitle('Home');

  return (
    <>
      <Banner
        primary="Senior Driving Discount of America"
        secondary="A Driving School of America Company"
      />
      <div className="ui main container">
        <HomeText showDetails={true} />
        <StateDropDown
          placeholder="Select a state"
          onSelectState={(st) => navigate(`/state/${st}`)}
        />
        <div style={{ height: '20px' }}></div>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ lineHeight: '0px' }}>Already signed up?</h3>
          <h3 style={{ lineHeight: '0px', paddingBottom: '10px' }}>
            Press the button below to resume.
          </h3>
          <div className="ui centered grid">
            <div className="row">
              <div className="column">
                <button
                  className="fluid ui primary button"
                  onClick={() => navigate(loggedIn() ? '/dashboard' : '/login')}
                >
                  Resume Course
                </button>
              </div>
            </div>
          </div>
          <div style={{ height: '20px' }}></div>
        </div>
      </div>
    </>
  );
};

export default Home;

export const HomeText = ({ showDetails }) => {
  const blurbStyle = {
    fontSize: '25px',
    fontWeight: 'bold',
    lineHeight: '1.4',
    marginButton: '20px',
  };
  return (
    <>
      <div style={blurbStyle}>
        The Senior Driving Discount of America offers online courses that could
        save you up to 15% off car insurance premiums.
      </div>
      <HomeImages />
      <div style={{ height: '15px' }}></div>
      {showDetails && (
        <>
          The Senior Driving Discount of America courses are specifically
          designed for drivers 50 years of age and older who want to reduce
          their insurance premiums. The course is 100% online! It can be
          completed in approximately a few hours. Once it is completed, a
          certificate will be sent to the email address you registered with, and
          you will always have a digital copy to download through your account.
        </>
      )}
      <div
        style={{
          fontWeight: 'bold',
          fontSize: '25px',
          lineHeight: 1.4,
          marginTop: '25px',
          marginBottom: '10px',
        }}
      >
        Select a state from the drop down below to find the course for you.
      </div>
    </>
  );
};
